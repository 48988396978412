@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family:'Montserrat', sans-serif;
}

ul {
  text-decoration: none;
}

a {
  transition: all 0.2s ease-in-out;
}